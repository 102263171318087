@import 'color-palette';

$body-bg: $background-color;
$body-color: $text-color;
$theme-colors: (
  "primary": map-get($primary-palette, 500),
  "secondary": map-get($secondary-palette, A200),
);
$primary-50-bg: $background-color-accent;
$primary-50-color: map-get(map-get($primary-palette, contrast), 50);

$list-group-bg: $primary-50-bg;
$list-group-color: $primary-50-color;
$breadcrumb-bg: $primary-50-bg;
$breadcrumb-active-color: $primary-50-color;
$breadcrumb-color: $primary-50-color;
$card-bg: $primary-50-bg;

$jumbotron-bg: $primary-50-bg;
$jumbotron-color: $primary-50-color;

// sass variable map
$colors: (
  color-black: #FFBB00
);

// loop over each name, color
:root {
  // each item in color map
  @each $name, $color in $colors {
    --#{$name}: #{$color};
  }
}

@import "../../../node_modules/bootstrap/scss/bootstrap";
