@use '~@angular/material' as mat;

$primary-palette: mat.$indigo-palette;

$secondary-palette: mat.$amber-palette;

$warn-palette: mat.$red-palette;

$text-color: #000000;
$background-color: #FFFFFF;
$background-color-accent: map-get($secondary-palette, 50);
$background-color-border: map-get($secondary-palette, 50);
// $background-color-toolbar: map-get($primary-palette, 500); this color is used automatically without declaring it
