@use '~@angular/material' as mat;
@import "bootstrap-custom";
@import '~@angular/material/theming';
@import 'color-palette';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


$stockforecast-primary: mat.define-palette($primary-palette, 500);
$stockforecast-accent: mat.define-palette($secondary-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$stockforecast-warn: mat.define-palette($warn-palette);

$stockforecast-app-theme: mat.define-light-theme((
  color: (
    primary: $stockforecast-primary,
    accent: $stockforecast-accent,
    warn: $stockforecast-warn,
  )
));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($stockforecast-app-theme);


// Workaround for https://github.com/angular/material2/issues/4580.
mat-form-field .mat-form-field {
  &-underline {
    position: relative;
    bottom: auto;
  }

  &-subscript-wrapper {
    position: static;
  }
}
